.static-view {
	margin-top: var(--page-margin-top);

	.inner-banner {
		&.sporturlaub-v1 {
			background-image: url(RESOURCE/img/inner-banner-sport.jpg);
		}

		&.entdeckerurlaub-v1 {
			background-image: url(RESOURCE/img/banner-museum.jpg);
		}
	}


	ul {
		list-style: disc;
		padding-left: 30px;

		li {}
	}

	&.anreise-v1 {
		.inner-box-color {
			height: 100%;
			text-align: center;
			background: var(--color-secondary);
			display: flex;
			flex-wrap: wrap;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			padding: 50px;
			color: #fff;
			border-radius: 5px;

			h3 {
				color: #fff !important;
			}

			.btn-primary {
				background: #fff;
				color: var(--color-secondary);
				border-color: #fff;

				&:hover {
					background: var(--color-secondary);
					color: #fff;

				}
			}
		}
	}

	&.gaestebetrag-v1 {
		.table-responsive {
			margin-bottom: 30px;
		}
	}

	&.reiseversicherung-v1 {
		iframe {
			width: 100%;
			height: 600px;
			border: none;

			@media (max-width: 1199px) {
				height: 380px;
			}


			@media (max-width: 767px) {
				height: 270px;
			}

			@media (max-width: 550px) {
				height: 230px;
			}
		}
	}

	&.about-us-v1 {


		.about-img-bl {
			text-align: center;

			img {
				width: 350px;
			}
		}
	}

	.borkum-gallery {
		.justified-gallery {
			width: auto;
			height: auto !important;
			margin-left: -7px;
			margin-right: -7px;

			.gallery {
				width: 33.33% !important;
				position: static !important;
				height: auto !important;
				margin-bottom: 15px;
				padding: 0 7px;

				@media (max-width: 992px) {
					width: 50% !important;
				}

				@media (max-width: 767px) {
					width: 100% !important;
				}

				img {
					width: 100% !important;
					height: 280px !important;
					object-fit: cover;
					margin: 0 !important;
					position: static;

					@media all and (min-width: 993px) and (max-width: 1199px) {
						height: 250px !important;
					}
				}
			}

		}
	}
}