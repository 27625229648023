.vo-unit-body {
	#lastviewed-units {
		display: none !important;
	}
}

.unit-page-layout-v1 {
	margin-top: var(--page-margin-top);

	@media (max-width: 991px) {
		padding-top: 20px;
	}

	.unit-iframe-section {
		margin-top: var(--unit-section-mb);
		margin-bottom: var(--unit-section-mb);

		iframe {
			width: 100%;
			height: 550px;
			border: none;

			@media (max-width: 1199px) {
				height: 380px;
			}


			@media (max-width: 767px) {
				height: 270px;
			}

			@media (max-width: 550px) {
				height: 230px;
			}
		}
	}
}