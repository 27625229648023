#lastviewed-units {
	.owl-stage {
		margin: 0 auto;
	}
}

.footer-v1 {

	.main-footer {

		background-color: var(--footer-bg-color);
		color: var(--footer-font-color);
		border-top: 1px solid var(--footer-border-top-color);

		padding-left: 5vw;
		padding-right: 5vw;

		.headline {
			font-size: 16px;
			color: var(--footer-font-color);
			font-weight: bold;
			text-transform: uppercase;
		}

		.paymentmethods {
			width: 100%;
			text-align: center;
			margin-top: 0;


			@media (max-width: 991px) {
				text-align: left;
				margin-left: -10px;
			}

			.fa {
				color: var(--color-secondary);
				font-size: 2.3rem;
				padding-left: 10px;

				@media all and (min-width: 992px) and (max-width: 1199px) {
					font-size: 30px;
					padding-left: 0;
				}

				@media all and (min-width: 1200px) and (max-width: 1499px) {
					font-size: 30px;
					padding-left: 5px;
				}

			}
		}


		.logo {
			width: 250px;
			height: auto;
			margin-top: 0;
			padding-bottom: 20px;
		}

		.openings {
			font-style: italic;

			div {
				padding-top: 10px;
			}
		}

		a {
			color: var(--footer-font-color);
		}

		.social-icons {

			li {

				a {
					color: var(--footer-font-color);

				}

				.fa {

					color: var(--footer-font-color);
					font-size: 26px;

				}
			}

		}




		#button-addon1 {
			color: #ffc371;
		}

		.btn-link {
			background-color: var(--footer-btn-bg-color);

			.fa {
				color: var(--footer-icon-color);
			}
		}

		i {
			color: #ffc371;
		}

		.form-control::placeholder {
			font-size: 0.95rem;
			color: #aaa;
			font-style: italic;
		}

		.form-control.shadow-0:focus {
			box-shadow: none;
		}
	}

	.copyright {
		background-color: var(--footer-copyright-bg-color);
		color: var(--footer-copyright-font-color);

		a {
			color: var(--footer-copyright-font-color);
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}

		.v-office-logo {
			float: left;
			padding-right: 20px;

			img {
				width: 50px;
			}
		}
	}

}